import React, { useEffect, useState } from "react";
import { useIntl } from "gatsby-plugin-react-intl";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { truncate as _truncate } from "lodash";
import CalendarIcon from "../../assets/images/calendar.svg";
import defaultThumbImage from "../../assets/images/default_thumb.png";
import { optimizeImageUrl } from "../../utils/cloudinary";
import { AdvancedImage, responsive, lazyload } from '@cloudinary/react';
import { removeTags } from "../../utils/utils";
import { Link, navigate } from "gatsby-plugin-react-intl";


const SliderCard = props => {
  const [startDate, setStartDate] = useState("");
  const [availableDates, setAvailableDates] = useState([]);
  const event = props.event;
  const intl = useIntl();

  useEffect(() => {
    organizeAvailableDates(event.event_dates);
  }, [event]);

  const organizeAvailableDates = eventDates => {
    const available_dt = [];
    eventDates.map(event_date =>
      available_dt.push(new Date(event_date.datetime))
    );
    setAvailableDates(available_dt);
  };

  const startTime = dayjs("2021-06-06" + event.start_time);

  const isDeLang = () => intl.locale === 'de';

  const eventName = isDeLang() && event.name_de !== null ? event.name_de : event.name;

  return (
    <>
      <Link
        className="event-link-wrapper"
        to={`/event/${event.slug}`}
      >
        <div className="card-image slider-item">
          {event.thumbnail ? (
            <AdvancedImage
              className="image-thumb"
              cldImg={optimizeImageUrl(event.thumbnail, { quality: "auto" })}
              plugins={[responsive(), lazyload()]}
            >
            </AdvancedImage>
          ) : (
            <img
              className="image-thumb"
              src={defaultThumbImage}
              alt={"Placeholder image"}
            />
          )}
          <time className="level is-mobile" dateTime={event.date}>
            <span className="level-left">
              {dayjs(availableDates[0]).format("ddd D. MMM YYYY")}
            </span>
            <span className="level-right">
              {dayjs(startTime).format("HH:mm")}
            </span>
          </time>
        </div>
        <div>
          <div className="content">
            <h4 className="is-hidden-tablet">
              {_truncate(eventName, { length: 28, omission: "..." })}
            </h4>
            <h4 data-tooltip={eventName} className="is-hidden-mobile">
              {_truncate(eventName, { length: 28, omission: "..." })}
            </h4>
            <p>{removeTags(_truncate(isDeLang() ? event.description_de : event.description, { length: 120, omission: '...' }))}</p>
          </div>
        </div>
      </Link>
      <form onSubmit={e => {
        e.preventDefault();
        navigate(`/event/${event.slug}?date=${dayjs(startDate).format("MM/DD/YYYY")}`);
      }}>
        <div className="level is-mobile booking-options">
          <div className="level-left">
            <button className="button" type="submit">
              {intl.formatMessage({ id: "book_ticket" })}
            </button>
          </div>
          <div className="level-right">
            <div className="level-item has-text-centered">
              <div className="field">
                <div className="control has-icons-right">
                  <DatePicker
                    portalId="slider-root-portal"
                    name="date"
                    className="input time-range"
                    dateFormat="dd/MM/yyyy"
                    minDate={new Date()}
                    includeDates={availableDates}
                    placeholderText={intl.formatMessage({
                      id: "available_date",
                    })}
                    selected={startDate}
                    onChange={date => setStartDate(date)}
                    popperPlacement="bottom-end"
                    required
                    autoComplete="off"
                  />
                  <span className="icon is-right">
                    <img src={CalendarIcon} alt="Calendar icon" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default SliderCard;
