import React from 'react';
import { size as _size } from 'lodash';
import Layout from '../layouts/Layout';
import Seo from '../components/Seo/Seo';
import { useIntl } from 'gatsby-plugin-react-intl';
// import FlatCard from '../components/EventCard/FlatCard';
import EventCard from '../components/EventCard/EventCard';
import ArrowLeftIcon from '../assets/images/left-arrow.svg';
import SliderCard from '../components/EventCard/SliderCard';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import ArrowRightIcon from '../assets/images/right-arrow.svg';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import useGetEvents from '../services/events/useGetEvents';
import useThisWeekEvents from '../services/events/useThisWeekEvents';
import { QUERY_LONG_CACHE_TIME } from '../services/reactQueryConfig';
import { useSelector } from 'react-redux';

const IndexPage = () => {
  const intl = useIntl();
  // const [summary, setSummary] = useState([]);
  const groupName = useSelector((state) => state.group);



  const { data: events, isLoading } = useGetEvents({ limit: 30, group: groupName === 'categories' ? 'concert' : groupName },
    {
      staleTime: QUERY_LONG_CACHE_TIME,
      cacheTime: QUERY_LONG_CACHE_TIME,
      refetchOnWindowFocus: false,
    });

  const { data: thisWeekEvents, isLoading: thisWeekEventsLoading } = useThisWeekEvents({ group: groupName === 'categories' ? 'concert' : groupName }, {
    staleTime: QUERY_LONG_CACHE_TIME,
    cacheTime: QUERY_LONG_CACHE_TIME,
    refetchOnWindowFocus: false,
  });

  // const getSummary = () => {
  //   API.get('/events?limit=6')
  //     .then((res) => {
  //       setSummary(res.data);
  //     })
  //     .catch((err) => toast.error(err));
  // };

  return (
    <Layout section={'home'}>
      <section className="section homepage-content">
        <div className="container">
          {isLoading || thisWeekEventsLoading ? (
            <div className="loader-wrapper is-active">
              <div className="loader is-loading"></div>
            </div>
          ) : (
            <>
              {_size(thisWeekEvents) > 0 && (
                <>
                  <div className="columns latest-events">
                    <div className="column">
                      <div className="content">
                        <div className="level row-heading is-mobile">
                          <div className="level-left">
                            <h3>{intl.formatMessage({ id: 'this_week' })}</h3>
                          </div>
                        </div>

                        <div className="carousel-slider">
                          <Splide
                            options={{
                              rewind: true,
                              perPage: 3,
                              perMove: 1,
                              gap: '1.7rem',
                              pagination: false,
                              arrows: 'slider',
                              lazyLoad: 'nearby',
                              autoplay: true,
                              interval: 5000,
                              pauseOnHover: true,
                              pauseOnFocus: false,
                              breakpoints: {
                                1024: {
                                  perPage: 3,
                                },
                                600: {
                                  perPage: 1,
                                },
                              },
                            }}
                            renderControls={() => (
                              <div className="splide__arrows">
                                <button className="splide__arrow splide__arrow--prev button is-transparent arrow-left">
                                  <img src={ArrowLeftIcon} alt="Arrow left icon" />
                                </button>
                                <button className="splide__arrow splide__arrow--next button is-transparent arrow-right">
                                  <img
                                    src={ArrowRightIcon}
                                    alt="Arrow right icon"
                                  />
                                </button>
                              </div>
                            )}
                          >
                            {thisWeekEvents.map((thisWeekEvent) => (
                              <SplideSlide key={thisWeekEvent.id}>
                                <SliderCard
                                  key={thisWeekEvent.id}
                                  event={thisWeekEvent}
                                />
                              </SplideSlide>
                            ))}
                          </Splide>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {_size(events) > 0 && (
                <>
                  <div className="columns">
                    <div className="column">
                      <div className="content">
                        <div className="level row-heading">
                          <div className="level-left">
                            <h3>{intl.formatMessage({ id: 'all_events' })}</h3>
                          </div>
                        </div>
                        <div className="columns is-multiline row-items">
                          {events.map((event) => (
                            <EventCard key={event.id} event={event} />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {/* {_size(summary) > 0 && (
            <>
              <div className="columns">
                <div className="column">
                  <div className="content">
                    <div className="level row-heading">
                      <div className="level-left">
                        <h3>
                          {intl.formatMessage({
                            id: 'summary',
                            defaultMessage: 'Summary',
                          })}
                        </h3>
                      </div>
                    </div>
                    <div className="columns is-multiline row-items">
                      {summary.map((event) => (
                        <FlatCard key={event.id} event={event} />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )} */}
              {_size(events) === 0 && _size(thisWeekEvents) === 0 && (
                <div className="columns">
                  <div className="column">
                    <div className="content has-text-centered">
                      <p>No Events found with this search filter</p>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </section>
    </Layout>
  );
};

export default IndexPage;
export const Head = ({ location }) => (
  <Seo
    pathname={location.pathname}
  />
);
