import { useQuery } from '@tanstack/react-query';
import { eventKeys } from './eventKeys';
import { thisWeekEvents } from './api/EventsApi';

const useThisWeekEvents = ({ group }, queryConfig = {}) => {
  return useQuery(eventKeys.list({ group: group, 'this-week': true }), () => thisWeekEvents({ group }), {
    ...queryConfig,
  });
};

export default useThisWeekEvents;
