import { useQuery } from '@tanstack/react-query';
import { eventKeys } from './eventKeys';
import { getEvents } from './api/EventsApi';

const useGetEvents = ({ limit, group }, queryConfig = {}) => {
  return useQuery(eventKeys.list({ limit, group }), () => getEvents({ limit, group }), {
    ...queryConfig,
  });
};

export default useGetEvents;
